@font-face {
    font-family: 'koken';
    src: url('./../fonts/kokn.ttf');
}

/* ****************    **************** */
// Global Styles
/* ****************    **************** */

body{
    font-family: 'koken';
}

label{
    margin-bottom: 0;
}

input{
    text-align: right;
}

td{
    vertical-align: middle !important;
}

input[type="file"]{
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

/* ****************    **************** */
// Classes Styles
/* ****************    **************** */

.custom-shadow{
    box-shadow: 6px 10px 4px rgba(234, 234, 234, 0.15)
}

.custom-br{
    border-radius: 10px;
}

.lang-ar{
    direction: rtl;
    text-align: right;
}

.m-b-10{
    margin-bottom: 10px;
}

.page-title{
    font-size: 30px;
}

.txt-red{
    color: #d83939;
    font-weight: bold;
    direction: ltr;
}

.sata-btn,
.sata-btn:hover{
    background-color: #ff5a00;
    color: #fff;
}

/* ****************    **************** */
// Ant Styles Reset
/* ****************    **************** */

.ant-form-item-label{
    width: 100%;
}

.ant-form-item-label > label::after{
    display: none;
}

.ant-input-group,
.ant-input-affix-wrapper,
.ant-select-selection__placeholder,
.ant-select-search__field__placeholder,
.ant-select-dropdown-menu-item{
    text-align: right;
}

.ant-table table{
    border-collapse: collapse;
}

.ant-table-body{
    border-radius: 10px;
}

.ant-notification-notice-close{
    display: none;
}

.ant-notification-notice-icon{
    position: relative;
    top: 3px;
    margin-left: 0;
}

.ant-notification-notice-closable .ant-notification-notice-message{
    padding-right: 10px;
}

.ant-modal-close{
    right: auto;
    left: 0;
    &:focus{
        outline: none;
    }
}

.ant-modal-footer{
    display: none;
}

.table thead th, .table th,
.ant-table-thead > tr, .ant-table-tbody > tr{
    text-align: center;
}

.ant-pagination-item,
.ant-pagination-prev,
.ant-pagination-jump-prev,
.ant-pagination-jump-next{
    // margin-right: auto;
    margin-left: 8px;
}

.ant-pagination-prev,
.ant-pagination-next{
    transform: rotate(180deg);
}

.ant-select-selection-selected-value{
    float: right;
}

.ant-calendar-picker{
    display: block;
}

.ant-modal-header{
    text-align: right;
}

.ant-select-search input{
    width: 100% !important;
}

.ant-select-selection--multiple .ant-select-search--inline{
    width: 95% !important;
}

.ant-calendar-picker-icon {
    position: relative;
    top: -25px;
}

.ant-calendar-picker-input.ant-input {
    text-indent: 20px;
}

.ant-alert-icon{
    position: relative;
    top: 0.5px;
    left: 6px;
}

.ant-calendar-picker-icon{
    top: -27 !important;
    right: 90%;
    left: revert;
}

.ant-calendar-picker-input .ant-calendar-picker-icon{
    top: 0 !important;
    left: -100% !important;
}

.ant-calendar-picker {
    display: block;
    direction: ltr;
}

.custom-width{
    width: 95% !important;
}

.custom-date{
    text-align: left !important;
    width: 100% !important;
    color: #fff !important;
}

.confirm-winners{
    direction: ltr !important;
}
