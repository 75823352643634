/*
Template Name: Admin Template
Author: Niravjoshi / Wrappixel
File: scss
*/

//(Required file)
@import "variable";

//Theme colors
@import "theme-colors/dark-theme";

// Import Bootstrap source files (Required file)
@import "bootstrap/bootstrap";

//This is for the components (Required file)
@import "components";

//This is for the pages (if you are not using some pages you can remove that style from pages folder)
@import "pages";

//This is for the widgets (Required file)
@import "widgets/widgets";

//This is for the horizontal version if you are not using horizontal version you can remove this
@import "horizontal/horizontal";

//This is for the responsive (Required file)
@import "responsive";

//In This scss you can write your scss
@import "custom";

//This is for the icons (Required file)
@import "icons/font-awesome/css/fontawesome-all.css";
@import "icons/simple-line-icons/css/simple-line-icons.css";
@import "icons/weather-icons/css/weather-icons.min.css";
@import "icons/themify-icons/themify-icons.css";
@import "icons/flag-icon-css/flag-icon.min.css";
@import "icons/material-design-iconic-font/css/materialdesignicons.min.css";
@import "icons/crypto-icons/cryptocoins.css";
@import "icons/iconmind/iconmind.css";

// this is for the perfect scrollbar
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

html,
body {
  height: 100%;
}

// EXTRA
[data-theme="dark"] .form-control {
  background: white !important;
  border-color: transparent !important;
}
[data-theme="dark"] .bg-light {
  background: white;
}

.ant-input {
  background-color: white !important;
}

.ant-form-item-label > label {
  color: wheat;
}

.ant-modal {
  direction: rtl;
}

img,
i {
  cursor: pointer;
}

input.ant-calendar-picker-input {
  height: 35px !important;
}

// fix the all tabs size
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  width: 120px !important;
  text-align: center !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  font-size: 14px !important;
}

.ant-tabs-bar {
  direction: ltr !important;
}

@media screen and (min-width: 992px) {
  .w-md-50 {
    width: 50% !important;
  }
}

@media screen and (max-width: 992px) {
  .product-box {
    width: 100% !important;
  }

  .light-logo {
    float: left;
  }

  .ant-form-item-label > label {
    float: right;
  }

  .page-breadcrumb .page-title {
    font-size: 15px;
    color: white;
  }

  .topbartoggler {
    display: none !important;
  }
}

// .button-custom {
//     border-radius: 25px 0 25px 0 !important;
//     text-shadow: 0px 0px 7px white;
//     background: #b9aa3c;
//     color: black;
//     font-size: 17px;
// }

.ant-pagination-jump-next,
.ant-pagination-jump-prev {
  display: none;
}

.ant-pagination-item-link {
  border-radius: 25px !important;
  color: white !important;
  background-color: #2b3142 !important;
}

.ant-pagination-item-active {
  border: none !important;
  border-radius: 25px !important;
}

.p-datatable {
  th {
    font-size: 12px;
  }
  td {
    font-size: 12px;
    text-align: right !important;
  }
  .ant-table-column-title {
    display: flex;
    gap: 3px;
  }
  .p-button {
    width: 20px;
    height: 20px !important;
    padding: 15px;
  }
  tr td:last-child div {
    display: flex;
    gap: 3px;
  }
}

.p-toolbar,
.p-paginator {
  direction: ltr;
}

.p-toolbar {
  .p-button {
    padding: 5px;
    font-size: 13px;
  }
}

input {
  font-family: "Times New Roman", Times, serif;
}

.empty-table {
  .pi-inbox {
    font-size: 50px;
  }
}
.bg-white1 {
  background-color: white;
  .p-datatable tr {
    th,
    td {
      border-color: black !important;
    }
  }
}
.p-image-preview {
  height: 70vh;
}
.w-400px {
  width: 400px;
}

.p-inputnumber-buttons-vertical {
  width: 50px;
  height: 100px !important;
}
.p-card-body {
  padding: 10px !important;
}
.p-card-content {
  padding: 0px !important;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  margin-right: 0 !important;
  padding-right: 5px !important;
  padding-left: 10px !important;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
  right: unset;
  left: 10px;
}
.h-50px {
  height: 50px !important;
}
.p-dropdown-panel {
  left: unset !important;
  right: 0 !important;
}
.p-float-label > label {
  left: unset;
  right: 0.75rem;
}
.p-autocomplete-input {
  width: 100%;
}
.p-autocomplete .p-autocomplete-loader {
  right: unset;
  left: 0.75rem;
}
.pi-chevron-right:before {
  content: "\e900";
}
.pi-chevron-left:before {
  content: "\e901";
}
.p-timepicker {
  direction: ltr;
}
.p-button .p-button-icon-left {
  margin-right: 0;
  margin-left: 0.5rem;
}
.curser-pointer {
  cursor: pointer;
}

.p-inline-message .p-inline-message-icon {
  margin-right: 0;
  margin-left: 0.5rem;
}
.p-dropdown-clear-icon,
p-multiselect.p-multiselect-clearable .p-multiselect-clear-icon {
  right: unset !important;
  left: 3rem !important;
}

.p-multiselect-panel .p-checkbox {
  margin-right: 0;
  margin-left: 0.5rem;
}
