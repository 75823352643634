// Sidebar
$sidebar-link-padding: 12px 15px;
$sidebar-icon-size: 20px;
$sidebar-first-level-padding: 0 0 10px 0;
$sidebar-first-level-link-padding: 10px 15px;
$sidebar-first-level-icon-size: 14px;

/*******************
Main sidebar
******************/

.left-sidebar {
  position: absolute;
  width: $sidebar-width-full;
  height: 100%;
  top: 0px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 10;
  padding-top: 64px;
  background: $sidebar;
  //box-shadow: $shadow;
  transition: 0.2s ease-in;
}

/*******************
sidebar navigation
******************/
.scroll-sidebar {
  height: calc(100% - 20px);
  // position: relative;
}

.sidebar-nav {
  ul {
    .sidebar-item {
      .sidebar-link {
        color: $sidebar-text;
        padding: $sidebar-link-padding;
        display: flex;
        white-space: nowrap;
        align-items: center;
        line-height: 25px;
        opacity: 0.6;
        top: 5px;
        i {
          font-style: normal;
          width: 35px;
          line-height: 25px;
          font-size: $sidebar-icon-size;
          color: $sidebar-icons;
          display: inline-block;
          text-align: center;
        }
        &.active,
        &:hover {
          opacity: 1;
        }
      }
      &.selected > .sidebar-link {
        /*background: rgba(0, 0, 0, 0.04);*/
        opacity: 1;
        color: black;
        font-weight: bold;
      }
      /*******************
      First level
      ******************/
      .first-level {
        padding: $sidebar-first-level-padding;

        .sidebar-item.active .sidebar-link {
          opacity: 1;
        }
        .sidebar-item .sidebar-link {
          padding: $sidebar-first-level-link-padding;
          i {
            font-size: $sidebar-first-level-icon-size;
          }
        }
      }
    }
    /*******************
         Small cap
         ******************/
    .nav-small-cap {
      font-size: 12px;
      padding: $sidebar-link-padding;
      white-space: nowrap;
      display: flex;
      align-items: center;
      line-height: 30px;
      margin-top: 10px;
      color: $sidebar-text;
      opacity: 0.5;
      text-transform: uppercase;
      i {
        line-height: 30px;
        margin: 0 5px;
      }
    }
  }
}
/*******************
 common m-p removed
******************/
.sidebar-nav {
  ul {
    margin: 0px;
    padding: 0px;
    li {
      list-style: none;
    }
  }
  > ul > li.selected {
    padding: 0 0 0 10px;
    > a {
      border-radius: 60px 0 0 60px;
      background: rgb(246,225,58);
      background: linear-gradient(50deg, rgba(246,225,58,1) 0%, rgba(210,194,60,1) 25%, rgba(156,148,64,1) 50%, rgba(45,53,72,1) 100%);
      color: $white;
    }
  }
}
.collapse.in {
  display: block;
}
/*******************
Down arrow
******************/
.sidebar-nav .has-arrow {
  position: relative;
  &::after {
    position: absolute;
    content: '';
    width: 7px;
    height: 7px;
    border-width: 1px 0 0 1px;
    border-style: solid;
    border-color: $sidebar-text;
    margin-left: 10px;
    -webkit-transform: rotate(135deg) translate(0, -50%);
    -ms-transform: rotate(135deg) translate(0, -50%);
    -o-transform: rotate(135deg) translate(0, -50%);
    transform: rotate(135deg) translate(0, -50%);
    -webkit-transform-origin: top;
    -ms-transform-origin: top;
    -o-transform-origin: top;
    transform-origin: top;
    top: 23px;
    right: 15px;
    -webkit-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
}

/*.sidebar-nav .active > .has-arrow::after,*/

/*.sidebar-nav li.selected > .has-arrow::after,*/
.sidebar-nav li.active > .has-arrow::after,
.sidebar-nav li > .has-arrow.active::after,
.sidebar-nav .has-arrow[aria-expanded='true']::after {
  -webkit-transform: rotate(-135deg) translate(0, -50%);
  -ms-transform: rotate(-135deg) translate(0, -50%);
  -o-transform: rotate(-135deg) translate(0, -50%);
  transform: rotate(-135deg) translate(0, -50%);
}

/*******************
User Profile
******************/
.user-profile {
  padding: 15px;
  text-align: center;
  .user-content,
  .user-name,
  .user-email {
    color: $theme-light;
    position: relative;
    white-space: nowrap;
  }
  .user-pic {
    img {
      width: 60px;
    }
  }
  .btn-circle.btn-sm {
    width: 25px;
    height: 25px;
    font-size: 10px;
    padding: 5px 0;
    background: rgba(0, 0, 0, 0.2);
    color: $white;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }
  .dropdown-menu {
    width: 220px;
    margin-top: 15px;
    left: 0!important;
  }
  .dropdown-toggle::after {
    display: none;
  }
}

[dir=rtl] .dropdown-menu {
  position: absolute;
  bottom: 15px;
  will-change: transform;
  transform: translate(110px, 54px);
  margin: auto;
  color: white;
}
